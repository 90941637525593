import React from 'react'
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <>
      <AppBar component="footer" position="static" sx={{ bottom: 0, backgroundColor: '#000000' }}>
        <Container maxWidth="md">
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', py: 2 }}>
            <Typography component="div" variant="caption" sx={{ fontSize: '1.2em' }}>
              芋屋六三郎株式会社
            </Typography>
            <Typography component="div" variant="caption" sx={{ fontSize: '1.2em' }}>
              〒 573-0064
            </Typography>
            <Typography component="div" variant="caption" sx={{ fontSize: '1.2em' }}>
              大阪府枚方市北中振4-8-15
            </Typography>
            <Typography component="div" variant="caption" sx={{ fontSize: '1.2em' }}>
              TEL 072-837-7899
            </Typography>
            <Typography component="div" variant="caption" sx={{ fontSize: '1.2em' }}>
              E-mail info@imo636.com
            </Typography>
            <Typography component="div" variant="caption" sx={{ fontSize: '1.2em' }}>
              ©2023 Imoyarokusaburou
            </Typography>
          </Box>
        </Container>
      </AppBar>
    </>
  )
}

export default Footer