import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function CompanyProfile() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Header />
      <List sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper', margin: '0 auto', marginBottom: 1, paddingBottom: '1rem' }}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography variant="h5" sx={{ mt: isMobile ? 7 : 12, fontWeight: 'bold', fontSize: '1.5rem' }}>
                会社概要
              </Typography>
            }
            sx={{ textAlign: 'center' }}
          />
        </ListItem>
        <Divider variant="inset" component="li" sx={{ marginLeft: 2, marginRight: 2, marginBottom: '1rem' }} />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
              会社名
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                芋屋六三郎株式会社
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              創業
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                2023.1.26(2011.6.1)
              </Typography>
            </React.Fragment>
          }
        />

        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              本店所在地
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                大阪府枚方市北中振4-8-15              </Typography>
            </React.Fragment>
          }
        />

        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              連絡先
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                072-837-7899
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              取締役
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                渡邉 貴司
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              資本金
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                500万円
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              事業内容
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                コンビニ経営<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                焼き芋販売
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              従業員数
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                100人
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              売上高
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                8億6千万円
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              店舗数
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                6店舗
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              主な取引先
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                株式会社ローソンストア100<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                株式会社京都銀行<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                日本政策金融公庫<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                大阪府飲食業生活衛生同業組合<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                おぎ堂事務所(顧問税理士、社労士)<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="span"
                color="text.primary"
                variant="body"
              >
                親和法律事務所(顧問弁護士)
              </Typography>
            </React.Fragment>
          }
        />
      </List>
      <Footer />
    </>
  )
}

export default CompanyProfile