import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { GiDeathStar } from "react-icons/gi";
import { RiStarFill } from "react-icons/ri";
import {
  BsFillMoonStarsFill,
  BsFillCloudFill
} from "react-icons/bs";
import { FaStarOfLife } from "react-icons/fa";



const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#784af4',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active: true }} className={className}>
      <Check className="QontoStepIcon-completedIcon" />
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundImage:
      'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundImage:
    'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { className } = props;

  const icons = {
    1: <FaStarOfLife fontSize='x-large' />,
    2: <BsFillCloudFill fontSize='x-large' />,
    3: <GiDeathStar fontSize='x-large' />,
    4: <BsFillMoonStarsFill fontSize='x-large' />,
    5: <RiStarFill fontSize='x-large' />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ active: true }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function Recruitment() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const steps = ['1.店舗責任者', '2.副店長', '3.店長', '4.アシスタントマネジャー', '5.マネジャー'];
  return (
    <>
      <Header />
      <List sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper', margin: '0 auto' }}>
        <ListItem component="div" alignItems="flex-start">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="h5" sx={{ mt: isMobile ? 7 : 12, fontWeight: 'bold', fontSize: '1.5rem' }}>
                採用情報
              </Typography>
            }
            sx={{ textAlign: 'center' }}
          />
        </ListItem>
        <Divider variant="inset" component="li" sx={{ marginLeft: 2, marginRight: 2, marginBottom: '1rem' }} />
        <ListItemText
          component="div"
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
              仕事内容
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{
                  display: 'block',
                  fontSize: '1rem',
                  marginLeft: '1rem',
                  wordBreak: 'break-all',
                  marginRight: 2
                }}
                component="div"
                color="text.primary"
              >
                店舗運営、および焼き芋に関わるマネージメント
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          component="div"
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              募集対象
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                特になし(焼き芋好きなら尚好)
              </Typography>
            </React.Fragment>
          }
        />

        <ListItemText
          component="div"
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              勤務地
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                大阪府<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                京都
              </Typography>
            </React.Fragment>
          }
        />

        <ListItemText
          component="div"
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              勤務時間
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                24時間中実労8時間<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                3交代制シフト<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                日勤帯 8-17、13-22<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >

                夜間帯 22-7
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          component="div"
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              給与
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                23万〜28万
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          component="div"
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              待遇
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                交通費支給(上限2万円)<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                社保完備<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                退職金制度有<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                賞与<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                昇給<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                買い物補助<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                研修制度有<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                昇進制度有<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                制服貸与<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                独立支援
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          component="div"
          primary={
            <Typography
              component="div"
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                marginLeft: '1rem',
                fontSize: '1.5rem',
                marginTop: '1rem',
                marginBottom: '1rem'
              }}
            >
              昇進モデル
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Stack spacing={1}>
                <Stepper alternativeLabel activeStep={5} connector={<ColorlibConnector />}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        component="div"
                        StepIconComponent={props =>
                          <ColorlibStepIcon {...props} active={index <= 1} completed={index < 1} />
                        }
                      >
                        <Typography
                          component="div"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </React.Fragment>
          }

        />

        <ListItemText
          component="div"
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              休日
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                月8回
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem' }}>
              福利厚生
            </Typography>
          }
          component="div"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                ローソン福祉会<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                ベネフィットステーション<br />
              </Typography>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                その他
              </Typography>
            </React.Fragment>
          }
        />
      </List>
      <List component="div" sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper', margin: '0 auto', marginTop: 5, marginBottom: 1, paddingBottom: '1rem' }}>
        <ListItem component="div" alignItems="flex-start">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                会社情報
              </Typography>
            }
            sx={{ textAlign: 'center' }}
          />
        </ListItem>
        <Divider variant="inset" component="li" sx={{ marginLeft: 2, marginRight: 2, marginBottom: '1rem' }} />
        <ListItemText
          component="div"
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
              従業員数
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                component="div"
                color="text.primary"
              >
                108人(このうち女性62人、パート46人)
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemText
          component="div"
          primary={
            <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
              その他
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{
                  display: 'block',
                  fontSize: '1rem',
                  marginLeft: '1rem',
                  wordBreak: 'break-all',
                  marginRight: 2
                }}
                component="div"
                color="text.primary"
              >
                100円を中心とした品揃えにより子どもからお年寄りまで幅広い層から支持されています。
              </Typography>
            </React.Fragment>
          }
        />
      </List>
      <Footer />
    </>
  )
}

export default Recruitment