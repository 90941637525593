import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { List, ListItem, ListItemText, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";

function StoreList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Header />
      <List component="div" sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper', margin: '0 auto', marginBottom: 1, paddingBottom: '1rem' }}>
        <ListItem alignItems="flex-start" component="div">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="h5" sx={{ mt: isMobile ? 7 : 12, fontWeight: 'bold', fontSize: '1.5rem' }}>
                店舗一覧
              </Typography>
            }
            sx={{ textAlign: 'center' }}
          />
        </ListItem>
        <Divider variant="inset" component="div" sx={{ marginLeft: 2, marginRight: 2, marginBottom: '1rem' }} />
        <ListItem component="div">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
                門真エル西三荘店
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  〒517-0057<br />
                </Typography>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  大阪府門真市元町28-19
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem component="div">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
                枚方北中振店
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  〒573-0064<br />
                </Typography>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  大阪府枚方市北中振4-8-15
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem component="div">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
                京阪五条駅前店
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  〒605-0847<br />
                </Typography>
                <Typography
                  sx={{
                    display: 'block',
                    fontSize: '1rem',
                    marginLeft: '1rem',
                    wordBreak: 'break-all',
                    marginRight: 2
                  }}
                  component="div"
                  color="text.primary"
                >
                  京都府京都市東山区東橋詰町五条通大橋東入7
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem component="div">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
                向日寺戸店
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  〒617-0002<br />
                </Typography>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  京都府向日市寺戸町西田中瀬3-7
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem component="div">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
                茨木西田中町店
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  〒567-0027<br />
                </Typography>
                <Typography
                  component="div"
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  color="text.primary"
                  variant="body"
                >
                  大阪府茨木市西田中町1-1
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>


        <ListItem component="div">
          <ListItemText
            component="div"
            primary={
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '1rem', fontSize: '1.5rem', marginBottom: '1rem' }}>
                門真千石西町店
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  〒571-0014<br />
                </Typography>
                <Typography
                  sx={{ display: 'inline', marginBottom: '1rem', fontSize: '1rem', marginLeft: '1rem' }}
                  component="div"
                  color="text.primary"
                >
                  大阪府門真市千石西町1-37
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List >
      <Footer />
    </>)
}

export default StoreList