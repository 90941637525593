import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { Typography, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import IMG_1 from '../images/IMG_1.jpeg'
import IMG_2 from '../images/IMG_2.jpeg'
import IMG_3 from '../images/IMG_3.jpeg'
import IMG_4 from '../images/IMG_4.jpeg'
import Main_IMG from '../images/Main_IMG.png'
import useMediaQuery from '@mui/material/useMediaQuery';

const fadeImages = [
  {
    url: IMG_1,
    caption: 'First Slide'
  },
  {
    url: IMG_2,
    caption: 'Second Slide'
  },
  {
    url: IMG_3,
    caption: 'Third Slide'
  },
  {
    url: IMG_4,
    caption: 'Forth Slide'
  },
];

function Home() {
  const fadeProperties = {
    duration: 2000,//2s
    transitionDuration: 1500,
    infinite: true,
    indicators: false,
    arrows: false,
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  return (
    <>

      <Header />
      <List component="div" sx={{
        width: '100%',
        maxWidth: isMobile ? 360 : 1200,
        bgcolor: 'background.paper',
        margin: '0 auto'
      }}>

        <ListItem alignItems="flex-start" component="div">
          <ListItemText
            component="div"
            primary={
              <>
                <Typography component="div" variant="h5" sx={{ mt: isMobile ? 8 : 13, fontWeight: 'bold', fontSize: '1rem', marginBottom: 3 }}>
                  Imoya Rokusaburo Co., Ltd. is a company that operates convenience stores and sells baked sweet potatoes.
                </Typography>
                <Typography component="div" align="left" variant="h5" sx={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: 1 }}>
                  芋屋のホームページへお越し戴きありがとうございます。

                  弊社は2011年より焼き芋を販売しているコンビニ、ローソンストア100を運営している会社です。

                  店舗拡大に伴い一緒に焼き芋のようにホッコリした雰囲気を作り、
                  時には焼き芋のように熱く昨日までの自分を一緒に超えて戴ける社員、アルバイトを募集しています。

                  ご興味のある方は9-17時の間に下記まで連絡お待ちしています。<br />
                </Typography>
                <Typography>
                  TEL 072 837 7899
                </Typography>
              </>
            }
            sx={{ textAlign: 'center' }}
          />
        </ListItem>
        <Fade {...fadeProperties}>
          {fadeImages.map((fadeImage, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img style={{ padding: 0, width: '90vw', height: 'auto' }} src={fadeImage.url} alt='shop_slide' />
            </div>
          ))}
        </Fade>
      </List >
      <img style={{ padding: 0, width: '100vw', height: 'auto' }} src={Main_IMG} alt='main_img' />
      <Footer />
    </>
  )
}

export default Home
