import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import Home from './components/Home';
import Recruitment from './components/Recruitment';
import StoreList from './components/StoreList';
import CompanyProfile from './components/CompanyProfile';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/rucruitment" element={<Recruitment />} />
        <Route exact path="/storelist" element={<StoreList />} />
        <Route exact path="/profile" element={<CompanyProfile />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>
);



